<script lang="ts" setup>
import { ref } from "vue";

const localeRoute = useLocaleRoute();
const { t } = useI18n();

useSeoMeta({
  title: t("pages.index.meta.title"),
});

const tocLinks = [
  { id: "welcome" },
  { id: "expertise" },
  { id: "team" },
  { id: "contact-form" },
];

const contentContainer = ref<null | HTMLElement>(null);
const { activeTableOfContentsItemId } = useTableOfContent(contentContainer);
</script>

<template>
  <div class="background-pattern">
    <div class="toc-container page--toc">
      <TableOfContent
        :activeTocId="activeTableOfContentsItemId"
        :tocLinks="tocLinks"
        page="home"
      />
    </div>
    <div class="" ref="contentContainer">
      <section-component
        :anchor-tag-title="t('home.landingPage.anchorTagTitle')"
        id="welcome"
      >
        <template #title>
          <h1 class="text-h1">
            {{ t("home.landingPage.title") }}
          </h1>
        </template>

        <template #subtitle>
          {{ t("home.landingPage.subtitle") }}
        </template>

        <Hero image="images/hero/about.jpg" class="mb-16">
          {{ t("home.hero.subHeadline") }}
        </Hero>

        <card-component
          :excerpt="t('home.about.subtitle')"
          :order="1"
          :title="t('home.about.title')"
          class="d-flex justify-center align-center"
          :transparent="false"
          slug="company"
          style="flex: 1"
          :to="localeRoute({ name: 'about' })"
        />

        <navigation-button
          class="mt-8 ml-auto"
          :to="localeRoute({ name: 'about' })"
        >
          {{ t("button.learn-more-about", [t("home.about.navigationText")]) }}
        </navigation-button>
      </section-component>

      <section-component id="expertise">
        <template #title>
          <h2 class="text-h1">
            {{ t("home.expertise.title") }}
          </h2>
        </template>

        <template #subtitle>
          {{ t("home.expertise.subtitle") }}
        </template>

        <div class="hw-list">
          <card-component
            slug="shopware"
            card-color="1574ad"
            :excerpt="t('technologies.shopware.subtitle')"
            :title="t('technologies.shopware.title')"
            :to="
              localeRoute({
                name: 'shopware',
              })
            "
          />

          <card-component
            slug="app-dev"
            card-color="aee1ff"
            :excerpt="t('technologies.appDev.subtitle')"
            :order="3"
            :title="t('technologies.appDev.titleResponsive')"
            :to="
              localeRoute({
                name: 'software-development',
                hash: '#app-dev',
              })
            "
          />

          <card-component
            slug="ai"
            card-color="103d5c"
            :excerpt="t('technologies.artificialIntelligence.subtitle')"
            :title="t('technologies.artificialIntelligence.title')"
            href="https://saxony.ai"
            :external-link="true"
          />

          <card-component
            slug="mobile-apps"
            :excerpt="t('technologies.mobileApps.subtitle')"
            card-color="1fabff"
            :order="3"
            :title="t('technologies.mobileApps.title')"
            :to="
              localeRoute({
                name: 'software-development',
                hash: '#mobile-apps',
              })
            "
          />

          <card-component
            slug="dev-ops"
            :excerpt="t('technologies.devOps.subtitle')"
            :title="t('technologies.devOps.title')"
            card-color="103d5c"
            :to="
              localeRoute({
                name: 'software-development',
                hash: '#dev-ops',
              })
            "
          />
        </div>

        <navigation-button
          class="mt-8 ml-auto"
          :to="localeRoute({ name: 'expertise' })"
        >
          {{
            t("button.learn-more-about", [t("home.expertise.navigationText")])
          }}
        </navigation-button>

        <h3 class="text-h2 text-none text-center">
          {{ t("home.technologies") }}
        </h3>

        <icons-technology class="mx-8" />

        <navigation-button
          class="mt-8 ml-auto"
          :to="localeRoute({ name: 'technologies' })"
        >
          {{ t("button.learn-more-about", [t("home.technologies")]) }}
        </navigation-button>
      </section-component>

      <section-component id="team">
        <template #title>
          <h2 class="text-h1">
            {{ t("home.team.title") }}
          </h2>
        </template>

        <template #subtitle>
          {{ t("home.team.subtitle") }}
        </template>

        <p class="text-center">
          {{ t("home.team.contactsText1") }}
        </p>
        <p class="text-center">
          {{ t("home.team.contactsText2") }}
        </p>
        <div class="hw-grid hw-mb hw-mt-small w-100">
          <employees-project-managers />
        </div>

        <h3 class="text-h2 text-none text-center">
          {{ t("home.team.teamFotoTitle") }}
        </h3>

        <v-card
          class="hw-my-smaller"
          :to="localeRoute({ name: 'employees' })"
          :aria-label="
            t('button.learn-more-about', [t('home.team.navigationText')])
          "
        >
          <HwImage
            src="images/teams/teams_all.jpg"
            object-fit="fill"
            aspect-ratio="2.07"
          >
            <div class="d-flex h-100 flex-column justify-end">
              <div
                class="text-strong text-center py-5 d-none d-md-block"
                style="background-color: rgba(var(--v-theme-background), 0.9)"
              >
                {{ t("home.team.teamFotoText1") }} <br />
                {{ t("home.team.teamFotoText2") }}
              </div>
            </div>
          </HwImage>
        </v-card>

        <navigation-button
          :to="localeRoute({ name: 'employees' })"
          class="mt-8 ml-auto"
        >
          {{ t("button.learn-more-about", [t("home.team.navigationText")]) }}
        </navigation-button>

        <contact-form id="contact-form" class="toc-item" />
      </section-component>
    </div>
  </div>
</template>
